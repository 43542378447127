.gm-style .gm-style-iw-c {
    padding-bottom: 0px !important;
    min-width: 0px !important;
    transform: translate(-50%,-100%);
    border-radius: 0px;
    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.1);
    padding-left: 15 !important;
    padding-right: 0 !important;
    width: 300px;
}
.gm-style .gm-style-iw-t::after {
    margin-top: -1px
}

.gm-style .gm-style-iw-c a {
  text-decoration: none;
  color: #3f51b5;
}

.gm-style .gm-style-iw-c a:hover {
  text-decoration: underline;
}

.gm-style .gm-style-iw-c img {
  height: 120px;
}

@media only screen and (max-width: 768px) {
  .gm-style .gm-style-iw-c img {
    height: 80px;
  }
}
