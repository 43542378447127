.MuiSlider-thumb:not(.MuiSlider-active) {
    transition: left 1s ease-in;
}
.MuiSlider-track {
  transition: width 1s ease-in;
}
@font-face {
    font-family: "Frankfurter Std Regular";
    src: local("Frankfurter"), url("assets/fonts/Frankfurter.ttf") format("truetype");
    font-weight: bold;
}
